.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio__item {
  background: rgba(22, 38, 44, 0.291);
  /* background: rgba(20, 20, 20, 1); */
  backdrop-filter: blur(15px);
  padding: 1.2rem;
  border-radius: 2rem;
  position: relative;
  border: 1px solid rgba(22, 38, 44, 0.6);
  transition: var(--transition);
}

/* .portfolio__item::before {
  background: radial-gradient(
    800px circle at var(--mouse-x) var(--mouse-y),
    /* 800px circle at 100px 100px, */
/* rgba(255, 255, 255, 0.06),
    transparent 40%
  );
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
} */

.portfolio__item:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.portfolio__item-image-div {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item-image {
  height: 360px;
  /*width: 480px;*/
  object-fit: cover;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.portfolio__modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  overflow-y: scroll;
}

.portfolio__modal-content {
  width: 80%;
  position: relative;
  background-color: rgba(20, 31, 36, 0.781);
  backdrop-filter: blur(25px);
  padding: 2.5rem 2.5rem 2.5rem;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  border-radius: 1.5rem;
  /* color: var(--color-white); */
  color: rgba(255, 255, 255, 0.8);
}

.portfolio__modal-close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 1.5rem;
  color: var(--color-white);
  cursor: pointer;
  z-index: 2;
}
.portfolio__modal-close:hover {
  color: var(--color-primary);
}

.portfolio__modal-title,
.portfolio__title-description {
  text-align: center;
}
.portfolio__modal-title {
  margin-bottom: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  font-size: 2.5rem;
  padding: 0;
}

.porfolio__modal-btn {
  margin-top: 1.2rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.portfolio__modal-subheading {
  text-align: center;
  font-weight: 400;
}

.portfolio__modal-heading {
  margin-top: 3.5rem;
  margin-bottom: 0.8rem;
  text-align: center;
  font-weight: 600;
  font-size: large;
}

.portfolio__modal-para p {
  /* text-align: center; */
  letter-spacing: 0.07em;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
}

.portfolio__modal h2 {
}

.portfolio__modal-details {
  display: flex;
  /*grid_template_columns: 16px 1fr;*/
  gap: 1rem;
}

.portfolio__modal-details-icon {
  margin-top: 6px;
  flex-shrink: 0;
  color: var(--color-primary);
}

.porfolio__modal-image-div {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__modal-image {
  object-fit: cover;
}

.porfolio__modal-para-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.3rem;
  margin-top: 1.5rem;
  align-items: center;
}

/*active modal*/

.active-modal {
  opacity: 1;
  visibility: visible;
}

.portfolio__modal-structure1 {
  text-align: center;
  margin-top: 1.3rem;
}

.portfolio__modal-structure2 {
  text-align: center;
  margin-top: 0.5rem;
}

.portfolio__modal-para > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .portfolio__modal-keywords {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2rem;
  justify-content: center;
  margin-top: 4rem;
} */

.portfolio__modal-keywords {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  margin-top: 4rem;
}

.portfolio__modal-achievements {
  display: grid;
  justify-content: center;
  gap: 2rem;
}

.portfolio__modal-p21-features {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}

.so_nlp_img {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}
/* ========== Media Queries (MEDIUM Devices: tablets) ========= */

@media screen and (max-width: 1324px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }

  .portfolio__modal-keywords {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .so_nlp_img {
    width: 90%;
  }

  .porfolio__modal-para-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.3rem;
    margin-top: 1.5rem;
    align-items: center;
  }
  .portfolio__modal-para-grid-text {
    margin-top: 2rem;
  }
}

.so__nlp-results {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 3rem;
}

/* ========== Media Queries (Small Devices: tablets) ========= */

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
  }
  .portfolio__modal-p21-features {
    flex-direction: column;
    align-items: center;
  }
  .btn-expai {
    flex-direction: column;
  }
}
