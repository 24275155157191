header {
  min-height: 100vh;
  /* padding-top: 7rem; */
  /* overflow: hidden; */
}

.header__container {
  text-align: center;
  /* margin-top: 10rem; */
  padding-top: 8rem;
  /* height: 100%; */
  /* position: relative; */
  /* margin-bottom: 10rem; */
}

/* ========= CTA =========== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}
.header__image-row {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  position: relative;
}

/* ========= Header Socials =========== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 2rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ========= ME =========== */
.me {
  background: 
  /* radial-gradient(
      350px circle at 100px 150px,
      rgba(0, 0, 0, 0.506),
      transparent 30%
    ), */
     /* radial-gradient(
      300px circle at 120px 200px,
      transparent 30%,
      rgba(16, 16, 16, 0.92)
    ), */ linear-gradient(
      transparent 5%,
      var(--color-bg)
    ),
    url(../../assets/me-bewerbung.png);
  /* mix-blend-mode: lighten; */
  /* background-blend-mode: multiply; */
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 30rem;
  min-width: 20rem;

  max-width: 22rem;
  max-height: 30rem;
  margin-top: 4rem;
  margin-left: auto;
  margin-bottom: 0;
  margin-right: auto;
  /* border: 0.5px solid; */
  overflow: hidden;
  padding: 2rem 0 0 0;
  /* position: relative; */
}

/* ========= Scroll Down =========== */

.scroll__down-div {
  position: absolute;
  display: flex;
  right: 0;
  bottom: 2rem;
  flex-direction: column;
  writing-mode: vertical-lr;
}

.scroll__down {
  font-weight: 300;
  font-size: 0.9rem;
}

/* ========== Media Queries (MEDIUM Devices: tablets) ========= */

@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }
}

/* ========== Media Queries (Small Devices: tablets) ========= */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .me {
    max-width: 19rem;
  }
}

/* ========== Media Queries (Small Devices: tablets) ========= */

@media screen and (max-width: 376px) {
  header {
    min-height: 130vh;
  }

  .me {
    max-width: 14rem;
    /*left: calc(50% - 7rem);*/
    /*bottom: 4rem;*/
    padding-top: 1.5rem;
  }
}
