.container.contact__container {
  display: flex;
  justify-content: center;
}

.contact__options {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}

.contact__option {
  background: var(--color-card-bg);
  backdrop-filter: blur(15px);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid var(--color-card-border);
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: small;
}

/* ========== FORM =========== */

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

/* ========== Media Queries (MEDIUM Devices: tablets) ========= */

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .contact__options {
    gap: 2rem;
  }
}

/* ========== Media Queries (Small Devices: tablets) ========= */

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
  .contact__options {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
}
