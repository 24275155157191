@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  /* border: 0; */
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-weight: 200;
}
/* * selects all the elements on the webpage* */

:root {
  /* --color-bg: #06151a; */
  --color-bg: #0e1416;
  --color-bg-variant: #0d333f;
  --color-primary: rgb(34 211 238 / 0.6);
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-secondary: rgba(238, 160, 34, 0.6);
  --color-secondary-vibrant: rgba(238, 160, 34, 1);
  --color-secondary-variant: rgba(255, 204, 77, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.74);

  /* --color-card-bg: rgb(23, 31, 33);
  --color-card-border: rgba(37, 49, 54, 1); */

  --color-card-bg: rgba(22, 38, 44, 0.291);
  --color-card-border: rgba(22, 38, 44, 0.6);

  --transition: all 400ms ease;

  --container-width-lg: 75%; /* width of container class wrapped around other elements'*/
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  /* background-image: url(./assets/bg-texture.png); */
  background: url(./assets/bg-texture.png), var(--color-bg);
  /* background-image: url(./assets/me-bewerbung.png); */
  /* background-image: linear-gradient(
    to bottom,
    rgb(26, 26, 26),
    rgba(32, 32, 32, 0.995)
  ); */
}

/* =========================== General Styles ============================== */

.container {
  width: var(--container-width-lg);
  margin: 0 auto; /* to position in the middle horizontally */
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

/* almost all components are going to be wrapped in a section tag */
section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-white);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-light);
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: medium;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: transparent;
  border: 1px solid var(--color-secondary-vibrant);
  color: var(--color-light);
}

.btn-secondary {
  background: rgba(28, 61, 72, 1);
  color: var(--color-white);
  border: 1px solid transparent;
}

.btn-secondary:hover {
  background: var(--color-white);
  border-color: 1px solid var(--color-bg);
}

.btn-tertiary {
  color: var(--color-white);
  border: 1px solid var(--color-primary);
}

.btn-secondary:hover {
  background: var(--color-white);
  border-color: 1px solid var(--color-bg);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ========== Media Queries (MEDIUM Devices: tablets) ========= */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-top: 6rem;
  }
}

/* ========== Media Queries (Small Devices: tablets) ========= */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section > h2 {
    margin-bottom: 2rem;
  }
}

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 3s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}
