.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
  align-items: center;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(350deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(360deg);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  /* background: #081c23; */
  background: var(--color-card-bg);
  backdrop-filter: blur(15px);
  border: 1px solid rgba(22, 38, 44, 0.6);
  border-radius: 10px;
  transition: var(--transition);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 1rem;
}

.about__card:hover::before,
.about__card:hover > .about__card_border {
  opacity: 1;
}

.about__card::before,
.about__card > about__card_border {
  opacity: 0;
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  position: absolute;
  transition: opacity 500ms;
  top: 0px;
  width: 100%;
}

.about__card::before {
  background: radial-gradient(
    400px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.06),
    transparent 40%
  );
  z-index: 3;
}

.about__card > .about__card_border {
  background: radial-gradient(
    100px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.5),
    transparent 40%
  );
  z-index: 1;
}

.about__card > .about__card_content {
  margin: 1px;
  background-color: var(--color-card-bg);
  border-radius: inherit;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 4px;
  position: relative;
  padding: 1rem;
  z-index: 2;
}

/* .about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
} */

.about__icon {
  color: var(--color-secondary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

.about__read-more {
  text-align: center;
  color: var(--color-secondary);
}

.about__span {
  color: var(--color-light);
}

/* ========== Media Queries (MEDIUM Devices: tablets) ========= */

@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/* ========== Media Queries (Small Devices: tablets) ========= */

@media screen and (max-width: 600px) {
  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }
  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}
